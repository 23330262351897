import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import {NftCollection} from '@/model/resource/NftCollection'
import * as Component from '@simpli/vue-render-schema'
import RenderCopy from '@/components/RenderCopy.vue'

export class ListNftCollectionSchema extends DefaultSchema {
  readonly name = 'ListNftCollection'

  readonly fieldSet: FieldSet<NftCollection> = {
    idNftCollectionPk: (): FieldComponent => ({
      is: Component.Render,
    }),
    imageUrl: (schema): FieldComponent => ({
      is: Component.RenderImage,
      bind: {
        src: schema.model.imageUrl,
        alt: this.translateFrom(schema.fieldName),
        innerClass: 'w-32',
      },
    }),
    name: (): FieldComponent => ({
      is: Component.Render,
    }),
    hash: (): FieldComponent => ({
      is: RenderCopy,
    }),
    connectedDapp: (schema): FieldComponent => ({
      is: Component.Render,
      bind: {
        content: schema.model.dapp?.title,
      },
    }),
  }
}

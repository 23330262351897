/**
 * Input Schema of NFT Collection
 * @author Lopes
 */
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet, FieldComponent} from '@simpli/meta-schema'
import FileManagerWithList from '@/components/FileManagerWithList.vue'
import {$} from '@/facade'
import {NftCollection} from '@/model/resource/NftCollection'

export class InputNftCollectionSchema extends DefaultSchema {
  readonly name = 'InputNftCollectionSchema'

  readonly fieldSet: FieldSet<NftCollection> = {
    imageUrl: (schema): FieldComponent => ({
      is: FileManagerWithList,
      bind: {
        folder: 'nftCollection',
        compressDimension: $.auth.global.dappImageDimension,
        crop: true,
        class: 'scoped__image-url w-full',
      },
    }),
  }
}

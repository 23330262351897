























































































import {Vue} from 'vue-property-decorator'
import {ListNftCollectionSchema} from '@/schema/resource/NftCollection/ListNftCollectionSchema'
import {NftCollectionCollection} from '@/model/collection/NftCollectionCollection'
import Component from 'vue-class-component'
import NftCollectionModal from '@/components/NftCollectionModal.vue'
@Component({
  components: {NftCollectionModal},
})
export default class ListNftCollectionView extends Vue {
  schema = new ListNftCollectionSchema()
  collection = new NftCollectionCollection()

  async mounted() {
    this.applyCollection()
    await this.populateResources()
  }

  applyCollection() {
    this.collection.orderBy = 'idContractPk'
    this.collection.asc = true
  }

  async populateResources() {
    const promises: Array<Promise<any>> = [this.collection.expand()]

    await this.$await.run('hardQuery', () => Promise.all(promises))
  }
}





































import {Component, Prop, Vue} from 'vue-property-decorator'
import {NftCollection} from '@/model/resource/NftCollection'
import {InputNftCollectionSchema} from '@/schema/resource/NftCollection/InputNftCollectionSchema'

@Component({})
export default class NftCollectionModal extends Vue {
  @Prop({type: NftCollection, required: true}) nft!: NftCollection

  showModal: boolean = false
  schema = new InputNftCollectionSchema()
  open = false

  private toggleModal(): void {
    this.showModal = !this.showModal
  }

  async persistContract() {
    await this.nft.persist()
    this.$toast.success('system.success.persist')
    this.showModal = false
    this.$emit('persisted')
  }
}
